.svg-color-blue,
.hover-svg-color-blue:hover,
.hover-svg-color-blue:focus {
  *[fill^='#']   { fill: color('blue'); }
  *[stroke^='#'] { stroke: color('blue'); }
}

.svg-color-red,
.hover-svg-color-red:hover,
.hover-svg-color-red:focus {
  *[fill^='#']   { fill: color('red'); }
  *[stroke^='#'] { stroke: color('red'); }
}

.svg-color-white,
.hover-svg-color-white:hover,
.hover-svg-color-white:focus {
  *[fill^='#']   { fill: color('white'); }
  *[stroke^='#'] { stroke: color('white'); }
}

.svg-height-100 svg { height: 100%; }
.svg-width-100  svg { width: 100%; }
.svg-flex-auto  svg { flex: auto; }
