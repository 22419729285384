.transform-flip-h {
  @include transform-flip('h');
}

.transform-flip-v {
  @include transform-flip('v');
}

.transform-flip-hv {
  @include transform-flip('hv');
}
