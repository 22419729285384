.line {
  display: flex;
  align-items: center;
  svg { display: block; }
}

.line--v {
  flex-direction: column;
}

.line > * {
  flex: none;
}

.line--h > .dots {
  padding-left: 10px;
  padding-right: 10px;
}

.line--h > .text {
  padding-left: 20px;
  padding-right: 20px;
}

.line--v > .dots {
  padding-top: 10px;
  padding-bottom: 10px;
}

.line > .animated-dot {
  .dot-larger-animated .fill   { animation: blink 250ms infinite step-start;  }
  .dot-larger-animated .border { animation: blink 250ms infinite step-end; }
}

// Don't pad first or last elements
.line--h > *:first-child { padding-left: 0; }
.line--h > *:last-child  { padding-right: 0; }
.line--v > *:first-child { padding-top: 0; }
.line--v > *:last-child  { padding-bottom: 0; }

.line > .segment {
  flex: 1;
  background: green;
  display: flex;
  align-items: center;

  svg { flex: auto; }
}

.line--h > .segment { flex-flow: row; }
.line--v > .segment { flex-flow: column; }


@keyframes stretch-h {
  0%   { flex: none; width: 0; }
  100% { flex: 1;    width: auto; }
}

@keyframes stretch-v {
  0%   { flex: none; height: 0; }
  100% { flex: 1;    height: auto; }
}

.line--h .segment--grow,
.line--h .segment--shrink {
  animation: stretch-h infinite 400ms steps(5, end) forwards;
}

.line--v .segment--grow,
.line--v .segment--shrink {
  animation: stretch-v infinite 400ms steps(5, end) forwards;
}

.line--h .segment--shrink,
.line--v .segment--shrink {
  animation-direction: reverse;
}
