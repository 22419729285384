*, *::before, *::after {
  box-sizing: border-box;
}

body {
  position: relative;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
}

button {
  font-family: inherit;
  font-size: 1em;
}

svg {
  display: block;
}

@keyframes blink {
  0%, 100% { opacity: 0; }
  50%      { opacity: 1; }
}

@keyframes flash-color-white-red {
  0%, 100% { color: color('white'); }
  50%      { color: color('red'); }
}

@keyframes flash-color-red-blue {
  0%, 100% { color: color('red'); }
  50%      { color: color('blue'); }
}
