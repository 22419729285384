.flex {
  display: flex;
}

.flex-1 { flex: 1; }
.flex-2 { flex: 2; }
.flex-3 { flex: 3; }
.flex-4 { flex: 4; }

.flex-auto { flex: 1 1 auto; }
.flex-grow { flex: 1 0 auto; }
.flex-10   { flex: 1 0; }
.flex-11   { flex: 1 1; }
.flex-none { flex: none; }

.flex-col { flex-direction: column; }
.flex-row { flex-direction: row; }
.flex-col-reverse { flex-direction: column-reverse; }
.flex-row-reverse { flex-direction: row-reverse; }

.flex-wrap   { flex-wrap: wrap; }
.flex-nowrap { flex-wrap: nowrap; }

// Cross Axis, Container
.items-start     { align-items: flex-start; }
.items-end       { align-items: flex-end; }
.items-center    { align-items: center; }
.items-baseline  { align-items: baseline; }
.items-stretch   { align-items: stretch; }

// Main Axis, Container
.justify-start   { justify-content: flex-start; }
.justify-end     { justify-content: flex-end; }
.justify-center  { justify-content: center; }
.justify-between { justify-content: space-between; }
.justify-around  { justify-content: space-around; }

// Cross Axis (Extra Space), Container
.content-start   { align-content: flex-start; }
.content-end     { align-content: flex-end; }
.content-center  { align-content: center; }
.content-between { align-content: space-between; }
.content-around  { align-content: space-around; }
.content-stretch { align-content: stretch; }

// Cross Axis, Element
.self-start      { align-self: flex-start; }
.self-end        { align-self: flex-end; }
.self-center     { align-self: center; }
.self-baseline   { align-self: baseline; }
.self-stretch    { align-self: stretch; }

// Order, Element
.order-0    { order: 0; }
.order-1    { order: 1; }
.order-2    { order: 2; }
.order-3    { order: 3; }
.order-4    { order: 4; }
.order-5    { order: 5; }
.order-6    { order: 6; }
.order-7    { order: 7; }
.order-8    { order: 8; }
.order-9    { order: 9; }
.order-last { order: 99999; }
