@keyframes flip-h {
  0%, 100% { transform: scaleX(1); }
  75%      { transform: scaleX(-1); }
}

@keyframes flip-v {
  0%, 100% { transform: scaleY(1); }
  75%      { transform: scaleY(-1); }
}

//
// Wiggly border around the site
// This uses PNG because repeating SVG patterns sometimes don't line up perfectly,
// and these particular PNGs are small files anyway.
//
.edge {

  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  $border-width: 20px;

  display: flex;
  flex-flow: column nowrap;
  align-items: stretch;

  // Top and Bottom edges
	&__hor {
    display: flex;
    height: $border-width;

    // The four corners
    &__corner {
      width: $border-width;
      flex: none;
      // Add the same background corner to all of corners,
      // and use transforms later to rotate these elements,
      // thus using only a single request/file
      background-image: url('/assets/images/wiggly-border-corner-top-left.svg');
      background-size: contain;
  	}

  	&__line {
      flex: 1;
      background-image: url('/assets/images/wiggly-border-h.svg');
      background-size: contain;
      background-repeat: repeat-x;
      animation: 400ms flip-v step-start infinite;
  	}
	}

  // Rotate all corners but the top left corner
  // (the corner image represents the top left one)
  &__hor--top    &__hor__corner--right  { @include transform-flip('h'); }
  &__hor--bottom &__hor__corner--left   { @include transform-flip('v'); }
  &__hor--bottom &__hor__corner--right  { @include transform-flip('hv'); }

  // Middle segment, which contains left and right edges
	&__ver {

    flex: 1;
    display: flex;
    align-items: stretch;
    justify-content: space-between;

    &__line {
      width: $border-width;
      background-image: url('/assets/images/wiggly-border-v.svg');
      background-size: contain;
      background-repeat: repeat-y;
      animation: 400ms flip-h step-start infinite;
      height: calc(auto+1px); // add one extra pixel to avoid rounding errors
    }
	}

}
