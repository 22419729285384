@mixin transform-flip($option) {

  @if $option == 'h' {
    transform: scaleX(-1);
    filter: FlipH;
  }
  @else if $option == 'v' {
    transform: scaleY(-1);
    filter: FlipV;
  }
  @else if $option == 'hv' {
    transform: scaleY(-1) scaleX(-1);
    filter: FlipH FlipV;
  }
  @else {
    @error "$option must be 'h', 'v', or 'hv'.";
  }

}
