.medium {
  display: block;
} // un-hide the element

html, body {
  background-color: color('blue');
  min-height: 100vh;
}

body {
  padding: 0;
  margin: 0;

  display: flex;
  flex-flow: column nowrap;
  align-items: stretch;
  justify-content: center;
}

.medium {
  padding: 40px 7%;
  font-size: 16px;
}

.teaser {
  position: relative;
  z-index: 10;

  margin: 0 auto;
  max-width: 850px;

  padding-top: 150px;   // make space for the crown
}

.header-worlds-fair svg {
  padding: 20px;
  width: 100%;
  height: auto;
  margin: 0 auto;
  max-width: 700px;
}

.button,
.input {
  color: white;
}

.button {
  margin-left: 20px;
  margin-right: 20px;

  &:hover, &:focus {
    outline: none;
    animation: flash-color-white-red 250ms infinite step-start;
  }
}

.input {
  margin-bottom: 10px;

  &__field {
    max-width: 10.5em;
  }
}

.button__center__content,
.input__field {
  font-size: 28px;
  line-height: 1;
  letter-spacing: .13em;
}

.bottom {
  font-weight: 300;
  color: white;
  height: 55px;
  font-family: 'dispatch-extended', serif;
  text-transform: uppercase;
  font-size: 16px;
  letter-spacing: .125em;
  // -webkit-font-smoothing: antialiased;
  // -moz-osx-font-smoothing: grayscale;
}

.crown-burst {
  width: 163px;
  height: 154px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: calc(100% - 10px);
  background: url('/assets/images/crown-burst.svg');
}

// The “square” that surrounds the content
.square {
  &__vside {
    &__line-with-dots {
      margin-top: 13px;
      margin-bottom: 19px;
    }

    &--left &__line-with-dots {
      margin-right: 17px;
    }

    &--right &__line-with-dots {
      margin-left: 17px;
    }
  }
}

.crown-header {
  &__side {
    width: 24px;
    background: url('/assets/images/segment-white.svg') 0 53px repeat-x;
  }

  &__center {
    margin-bottom: 24px;
    background: url('/assets/images/segment-white.svg') top left repeat-y,
                url('/assets/images/segment-white.svg') top right repeat-y;
  }
}

.crown-line {
  height: 2px;
  margin-bottom: 14px;

  &__crown__img {
    transform: translateY(calc(-100% + 9px));
  }
}

.crown-line + * {
  margin-bottom: 18px;
}

// “Chicago”
.location {
  &__text {
    padding: 0 25px;
  }
}

// Triple line around “Chicago”
.triple-line {
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  min-height: 50px;

  svg {
    width: 100%;
  }

  &--left  { align-items: flex-start; }
  &--right { align-items: flex-end; }

  > * {
    width: 100%;
  }

  > *:nth-child(1),
  > *:nth-child(3) {
    width: 70%;
  }

}

.register {
  padding: 25px 20px;
}

@media (min-width: 850px) {

  .button__center__content,
  .input__field {
    font-size: 31px;
  }

  .bottom {
    font-size: 20px;
  }
}

@media (min-width: 950px) {
  .bottom {
    font-size: 22px;
  }
}
