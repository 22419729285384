@keyframes button-left-curl-move {
  from { transform: translate(0, 0); }
  to   { transform: translate(-4px, 10px); }
}
@keyframes button-right-curl-move {
  from { transform: translate(0, 16px); }
  to   { transform: translate(4px, 6px); }
}

.button, .input {
  font-family: 'united-sans-semi-extended';
  font-style: italic;
  font-weight: 300;

  appearance: none;
  background: none;
  border: 0;
  margin: 0;
  padding: 0;

  display: inline-flex;
  align-items: stretch;

  &__side {
    flex: none;
    display: flex;
    flex-flow: column;

    &--left {
      align-items: flex-end;
      justify-content: flex-end;
    }

    &--right {
      align-items: flex-start;
      justify-content: flex-start;
    }
  }

  &__center {
    display: flex;
    flex-flow: column;
    align-items: stretch;

    &__ruler {
      flex: none;
      display: flex;
      height: 16px;

      &--top {
        margin-right: -1px;
        margin-bottom: auto;
        margin-left: 9px;
      }

      &--bottom {
        margin-top: auto;
        margin-right: 9px;
        margin-left: -1px;
      }
    }

    &__ruler svg {
      flex: 1;
    }
  }

}

                .button__side--left svg .curl,
.input.is-focus .input__side--left  svg .curl {
  animation: button-left-curl-move 175ms steps(2, end) infinite both;
}

                .button__side--right svg .curl {
  animation: button-right-curl-move 175ms steps(2, end) infinite both;
}



//
// Button-specific styles
//
.button {
  cursor: pointer;

  &__center__content {
    font-style: italic;
    text-transform: uppercase;
    line-height: 1;
    padding: 0 .25em;
  }

}


//
// Input-specific styles
//

.input {

  &.is-focus &__field {
    outline: none;
  }

  &__field {
    font-family: inherit;
    font-style: inherit;
    font-weight: inherit;
    color: inherit;
    border: 0;
    appearance: none;
    border-radius: 0;
    background: none;
    text-transform: uppercase;
    line-height: 1;
    padding: .25em;
    margin-top: auto;
    margin-bottom: auto;
  }

  &__field::placeholder {
    color: currentColor;
    opacity: 0.5;
  }
}
