$card-padding: 18px;
$content-side-padding: 15%;

// Show the small layout
.small {
  display: block;
}

html, body {
  min-height: 100vh;
}

body {
  font-family: 'dispatch-extended', serif;
  font-weight: 400;

  background-color: #fd6e63;
  background-size: 2500px;
  background-position: center;
  background-attachment: fixed;

  display: flex;
  flex-flow: column nowrap;
  align-items: stretch;
  justify-content: center;

  font-size: 18px;
  padding: 2%;
}

.teaser {
  color: color('blue');
  background-color: color('pale-gray');

  // Don't make it huge
  margin: 0 auto;
  max-width: 500px;

  // Pad so content never reaches (background) borders
  padding: $card-padding;
}

.lines {
  height: 20px;
  border-top: solid 2px color('blue');
  border-bottom: solid 2px color('blue');
  margin-left: 35px;
  margin-right: 35px;
}

@keyframes border-shift {
  0% {
    background-position: left 0 top, right 0 top;
  }

  100% {
    background-position: left top 64px, right top 64px;
  }
}

.content {
  // left and right edges
  background-image: url('/assets/images/globe-line-blue-v.svg'),
                    url('/assets/images/globe-line-blue-v.svg');
  background-size: 22px 64px, 22px 64px;
  background-repeat: repeat-y, repeat-y;
  background-position: left top, right top;

  animation: border-shift 250ms infinite steps(3, start);

  margin: 15px 0;
  padding: 0 $content-side-padding;
}

.lettering {
  max-width: 300px;
  margin: 0 auto 2em;
  padding-top: 1em;
}

.lettering svg {
  width: 100%;
  height: auto;
}

.form {
  margin: 0 -#{$card-padding};
  padding: $card-padding 0;
  background-color: color('pale-gray');

  .form__wrapper {
    display: flex;
    flex-flow: column;
    align-items: center;
  }
}

.input {
  color: color('blue');
  margin-bottom: 10px;
}

.button {
  color: color('red');

  svg *[fill^='#']   { fill: currentColor; }
  svg *[stroke^='#'] { stroke: currentColor; }

  &:hover, &:focus {
    outline: none;
    animation: flash-color-red-blue 250ms infinite step-start;
  }

}

.button__center__content,
.input__field {
  font-size: 29px;
  letter-spacing: 2px;
}

.input__field {
  max-width: 10em;
}

.copy {
  line-height: 1.5;
  padding: 2em 0;
  text-transform: uppercase;
  font-style: normal;
  text-align: center;
  display: block;
}

@media (max-width: 370px) {

  body {
    padding: 0;
    font-size: 16px;
  }

  .teaser {
    padding: 15px;
  }

  .button__center__content,
  .input__field {
    font-size: 25px;
  }

}
