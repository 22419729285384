.large {
  display: block;
}

html, body {
  min-height: 100vh;
}

body {
  display: flex;
  flex-flow: column nowrap;
  align-items: stretch;
  justify-content: center;
  padding: 40px;
  background-color: color('blue');
  color: color('white');
}

// Overide straight corner of wiggly edge with angled corner
.large .edge__hor__corner {
  background-image: url('/assets/images/wiggly-border-corner-top-left-angled.svg');
}

.card {
  position: relative;
  padding: 20px;
  max-width: 1350px;
  margin: 0 auto;

  &__content {
    position: relative;
    z-index: 10;
    background-color: color('blue');
    overflow: hidden;
  }
}

.corner-band {
  position: absolute;
  z-index: 1;
  background-color: color('blue');
  border-color: color('red');
  border-width: 2px;
  border-style: solid none;
  padding: 18px 0;

  &--top-left {
    width: 65%;
    top: 0;
    left: 16%;
    // using margin-top instead of top to make top position
    // respond to card *width*, instead of height
    margin-top: 12%;
    transform: translate(-50%, -50%)
               rotate(-32.5deg);
  }

  &--bottom-right {
    width: 100%;
    bottom: 0;
    right: 15.5%;
    // using margin-bottom instead of bottom to make top position
    // respond to card *width*, instead of height
    margin-bottom: 10.5%;
    transform: translate(50%, 50%)
               rotate(-32.5deg);
  }


  &__content {
    padding: inherit;
    border-color: inherit;
    border-width: 1px;
    border-style: inherit;
  }

  svg {
    margin: 0 auto;
    padding: 0;
  }
}

.row-1 {
  padding: 15px;
  padding-right: 230px;
}

.row-1 .the {
  margin-right: 30%;
}

.row-1 .ornaments {
  width: 45%;
}

.row-1 > .crown {
  z-index: 10;
  position: absolute;
  top: 0;
  right: 0;

  #sparks { animation: blink 250ms step-start infinite; }
}

.bottom-right-burst {
  position: absolute;
  z-index: 10;
  bottom: 25px;
  right: 25px;
}

.row-2 {
  padding: 20px 30px;
  padding-left: 23%;
}

.worldsfair {
  margin-right: 15px;

  svg {
    width: 100%;
    height: auto;
  }
}

.row-3 {
  padding-left: 25px;
  padding-right: 25px;
}

#globe #state-1,
#globe #state-2 {
  animation: blink 250ms infinite;
}

#globe #state-1 {
  animation-timing-function: step-start;
}

#globe #state-2 {
  animation-timing-function: step-end;
}

@keyframes divider-shift {
  0% { background-position: 0 50%; }
  100% { background-position: 64px 50%; }
}

.divider {
  margin: 0;
  padding: 0;
  border: 0;
  height: 22px;
  background-image: url('/assets/images/globe-line-red-h.svg');
  background-size: contain;
  background-repeat: repeat-x;
  background-position: left;
  animation: divider-shift 250ms infinite steps(3, start);
}


.info {
  margin-right: 8%;
  padding: 30px 0;
}

.info .text {
  font-weight: 300;
  font-family: 'dispatch-extended', serif;
  text-transform: uppercase;
  font-size: 23px;
  letter-spacing: 2px;
}

.register {
  padding-top: 25px;
  padding-bottom: 25px;
}

.register__email {
  margin-right: 28%;
  margin-bottom: 15px;
}

.register__button {
  padding-left: 20px;
  margin-right: 40%;
}

.input {
  color: white;
}

.button {
  color: white;

  &:hover, &:focus {
    outline: none;
    animation: flash-color-white-red 250ms infinite step-start;
  }
}

.button__center__content,
.input__field {
  font-size: 31px;
  letter-spacing: 4px;
}

.input__field {
  max-width: none;
}
