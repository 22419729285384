$colors: (
  'blue': #04127E,
  'red':  #FC6E63,
  'pale-gray': #EBECF0,
  'white': #FFFFFF,
);
@function color($c) {
  @return map-get($colors, $c);
}

$bp-medium: 750px;
$bp-large: 1200px;
