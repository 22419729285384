/* Hide all layouts by default */
.small,
.medium,
.large {
  display: none;
}

@media (max-width: ($bp-medium - 1px)) {
  @import "layouts/_small"; 
}

@media (min-width: $bp-medium) and (max-width: ($bp-large - 1px)) {
  @import "layouts/_medium"; 
}

@media (min-width: $bp-large) {
  @import "layouts/_large"; 
}
